/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/swiper@6.8.4/swiper-bundle.min.js
 * - /npm/accordion-js@3.4.0/dist/accordion.min.js
 * - /npm/js-cookie@3.0.5/dist/js.cookie.min.js
 * - /npm/@ungap/custom-elements@1.3.0/es.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
